import React, { Component } from 'react';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../components/header/header';
import $ from 'jquery';
import './update-report.scss';
import ReactTooltip from 'react-tooltip';
import * as firebase from 'firebase';
import 'firebase/firestore';

export default class AdminUpdateReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      updatingReport: false,
      reports: null,
      reportData: {
        name: '',
        id: '',
        group_id: '',
        production: null
      },
      reportComponents: null,
    }
    this.getReports();
  }

  buildReportComponents = async () => {
    var reportComponents = [];
    reportComponents.push(<option key="default"></option>)
    this.state.reports.forEach((report) => {
      reportComponents.push(
        <option key={report["docName"]}>{report["docName"]}</option>
      )
    })
    await this.setState({ reportComponents: reportComponents });
  }

  getReports = async () => {
    var db = firebase.firestore();
    var reports = [];
    await db.collection('reports').get()
    .then(querySnapshot => {
      querySnapshot.docs.forEach(doc => {
        var docdata = {
          docName: doc.id,
          data: doc.data()
        }
        reports.push(docdata);
      });
    });
    this.setState({ reports: reports })
    this.buildReportComponents();
  }

  reportChanged = async (e) => {
    let name;
    if (e != null) {
      name = e.target.value;
    }
    if (name === '') {
      this.setState({
        reportData: {
          name: '',
          id: '',
          group_id: '',
          production: null
        }
      })
    }
    else {
      $('#name').prop('disabled', false);
      $('#id').prop('disabled', false);
      $('#group_id').prop('disabled', false);
      this.state.reports.forEach((report) => {
        if (report["docName"]=== name) {
          // for sending to controller
          this.setState({
            reportData: {
              name: report["data"]["name"],
              id: report["data"]["id"],
              group_id: report["data"]["group_id"],
              production: report["data"]["production"]
            }
          })
          // for updating input vals
          $('#name:text').val(report["data"]["name"]);
          $('#id:text').val(report["data"]["id"]);
          $('#group_id:text').val(report["data"]["group_id"]);
          if (name === "production") {
            $('#production').prop("checked", true);
            $('#production').prop("disabled", true);
          }
          else {
            $('#production').prop("checked", false);
            $('#production').prop("disabled", false);
          }
        }
      })
    }
  }

  handleCheckChange = () => {
    // toggles disabled prop everytime user clicks make production checkbox
    $('#name').prop('disabled', function (i, v) { return !v; });
    $('#id').prop('disabled', function (i, v) { return !v; });
    $('#group_id').prop('disabled', function (i, v) { return !v; });
  }

  handleTextChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState(prevState => ({ reportData: { ...prevState.reportData, [name]: value } }));
  }

  updateReport = async () => {
    this.setState({ updatingReport: true });
    var inputsValid = true;
    $('input').each(function () {
      if ($(this).val() === '') {
        inputsValid = false;
      }
    });
    if (!inputsValid) {
      toast.error('Missing inputs. All inputs are required.');
      this.setState({ savingUser: false });
      return;
    }

    let targetDoc;
    let production;
    if ($("#production").prop('checked')) {
      targetDoc = 'production';
      production = true;
    }
    else {
      targetDoc = 'dev';
      production = false;
    }


    try {
      var db = firebase.firestore();
      var docData = {
        name: this.state.reportData.name,
        id: this.state.reportData.id,
        group_id: this.state.reportData.group_id,
        production: production
      };
      db.collection('reports').doc(targetDoc).update(docData);
    } catch (err) {
      console.log(err);
    } 
    toast.success('Report updated!');
    this.setState({ updatingReport: false });
  }

  render() {
    return (
      <div className="admin-update-report">
        <Header history={this.props.history} />
        <div className="container">
          <div className="box">
            <div className="title">Update a Report</div>
            <div className="subtitle">Please select a report to update</div>
            <FormGroup>
              <Label>Report Release</Label>
              <Input type="select" name="select" id="select_report" onChange={this.reportChanged}>
                {this.state.reportComponents}
              </Input>
            </FormGroup>
            <div className={this.state.reportData.name === '' ? 'hidden' : ''}>
              <div className="form-group">
                <Label data-tip='group' data-for='group' className='input-title'>Report Name</Label>
                <ReactTooltip id='group'>
                  <p>Name of report in database</p>
                </ReactTooltip>
                <Input type="text"
                  className="text-input"
                  placeholder="Report Group"
                  onChange={this.handleTextChange}
                  onKeyDown={this.handleKeyPress}
                  name='name'
                  id='name'
                />
              </div>

              <div className="form-group">
                <Label data-tip='powerid' data-for='powerid' className="input-title">PowerBI Report ID</Label>
                <ReactTooltip id='powerid'>
                  <p>Report ID from PowerBI. Value is in the url of the report.<br />
                    EX. https://app.powerbi.com/groups/groupid/reports/<b>reportid</b>/ReportSection
                 </p>
                </ReactTooltip>
                <Input type="text"
                  className="text-input"
                  placeholder="Report ID"
                  onChange={this.handleTextChange}
                  onKeyDown={this.handleKeyPress}
                  name='id'
                  id='id'
                />
              </div>

              <div className="form-group">
                <Label data-tip='powergid' data-for='powergid' className="input-title">PowerBI GroupID</Label>
                <ReactTooltip id='powergid'>
                  <p>Group ID from PowerBI. Value is in the url of the report.<br />
                    EX. https://app.powerbi.com/groups/<b>groupid</b>/reports/reportid/ReportSection
                 </p>
                </ReactTooltip>
                <Input type="text"
                  className="text-input"
                  placeholder="GroupID"
                  onChange={this.handleTextChange}
                  onKeyDown={this.handleKeyPress}
                  name='group_id'
                  id='group_id'
                />
              </div>

              <div className="form-group">
                <Label className="input-title">Make Production?</Label>
                <Input type="checkbox"
                  className="checkbox-input"
                  placeholder="GroupID"
                  onChange={this.handleCheckChange}
                  name='production'
                  id='production'
                />
              </div>

              <Button className="update-report-button"
                onClick={this.updateReport}
                disabled={this.state.updatingReport}
              >
                {this.state.updatingReport ?
                  <div>
                    <b>Update Report     </b>
                    <i class="fas fa-spinner fa-spin"></i>
                  </div>
                  :
                  <div>
                    <b>Update Report</b>
                  </div>
                }
              </Button>

            </div>
          </div>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    );
  }
}
