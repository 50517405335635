import * as firebase from 'firebase';
import 'firebase/firestore';

export default class User {

  static async updateLoginTimestamp() {
    try {
      var db = firebase.firestore();
      var doc = await db.collection('users').doc(sessionStorage.getItem('uid')).get();
      var docData = {
        last_login: firebase.firestore.Timestamp.now()
      };
      doc.exists 
        ? db.collection('users').doc(sessionStorage.getItem('uid')).update(docData)
        : db.collection('users').doc(sessionStorage.getItem('uid')).set(docData);
    } catch (err) {
      console.log(err);
    }
  }

  static async getSiteAccess() {
    try {
      var db = firebase.firestore();
      var user_doc = await db.collection('users').doc(sessionStorage.getItem('uid')).get();
      var site_access_ids = user_doc.data().site_access_ids;

      var site_doc = await db.collection('data').doc('sites').get();
      var site_list = site_doc.data();

      var site_access = []
      site_access_ids.forEach((site_id) => {
        site_access.push(site_list[site_id]);
      });
      
      return site_access;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  static async checkIfAdmin() {
    try {
      var db = firebase.firestore();
      var doc = await db.collection('users').doc(sessionStorage.getItem('uid')).get();
      if (doc.data().admin === true) {
        sessionStorage.setItem('admin', 'true');
      }
      else if (doc.data().local_admin === true) {
        sessionStorage.setItem('local_admin', 'true');
      }
      else {
        sessionStorage.removeItem('admin');
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async getReport(release) {
    try {
      var db = firebase.firestore();
      var doc = await db.collection('reports').doc(release).get();
      return doc.data();
    }
    catch (e) {
      console.log(e);
    }
  }
}