import React, { Component } from 'react';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../components/header/header';
import $ from 'jquery';
import trackPage from '../../functions/googleAnalytics'
import * as firebase from 'firebase';

import './view-account.scss';

export default class ViewAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            admin: null,
            local_admin: null,
            first_name: null,
            last_name: null,
            company: null,
            email: null,
            user_site_ids: [],
            site_components: [],
            error: false
      }
        trackPage(window.location.pathname + window.location.search)
        this.buildSiteList();
    }
    
    setUserData = async (result) => {
        if (!this.state.error) {
            this.setState({ first_name: result.first_name });
            this.setState({ last_name: result.last_name });
            this.setState({ company: result.company });
            this.setState({ username: result.email });
            this.setState({ user_site_ids: result.site_access_ids });
            this.setState({ admin: result.admin });
            this.setState({ local_admin: result.local_admin })
        } else {
            toast.error("Error getting your document.");
        }
    }

    getUserData = async () => {
        var db = firebase.firestore();
        var uid = sessionStorage.getItem('uid');
        var user = db.collection('users').doc(uid);
        var _this = this;

        var doc = await user.get().then(function (doc) {
            if (doc.exists) {
                return doc.data();
            } else {
                // Document doesn't exists
                _this.setState({ error: true });
                console.log("Document not found.");
            }
        }).catch(function (e) {
            // Error getting document
            _this.setState({ error: true });
            console.log("Error loading document");
            });

        this.setUserData(doc);
    }

  buildSiteList = async () => {
    await this.getUserData();
    if (this.state.user_site_ids !== []) {
      var db = firebase.firestore();
      var _this = this;
      db.collection('data').doc('sites').get().then((doc) => {
        var site_list = doc.data();
        var site_components = [];

        var sorted_list = [];
        for (let site in site_list) {
          sorted_list.push([site, site_list[site]]);
        }
        sorted_list.sort((a, b) => {
          var x = a[1].toLowerCase();
          var y = b[1].toLowerCase();
          return x < y ? -1 : x > y ? 1 : 0;
        });

        // puts users accessible sites at top of sorted list
        this.state.user_site_ids.forEach((id) =>
          sorted_list.sort(function (x, y) { return x[0] == id ? -1 : y[0] == id ? 1 : 0; })
        );

        var user_ids = this.state.user_site_ids;
        sorted_list = sorted_list.slice(0, user_ids.length).reverse();

        sorted_list.forEach((site) => {
          site_components.push(
            <FormGroup key={site[0]} check>
              <Label id="disabled" check >
                  <Input type="checkbox" value={site[0]} disabled checked /> 
                {site[1]}
              </Label>
            </FormGroup>
          );
        });
        _this.setState({ site_components });
      });
    }
  }

    loaded = () => {
        $(".loading-screen").fadeOut(2000);
    }
            
    render() {
        return (
            <div className="view-account">
                <Header history={this.props.history} />
                <div className="loading-screen">
                    <div className="heart-rate">
                        <svg version="1.0" x="0px" y="0px" width="150px" height="73px" viewBox="0 0 150 73" enable-background="new 0 0 150 73">
                            <polyline fill="none" stroke="#38A2E8" stroke-width="3" stroke-miterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486" />
                        </svg>
                        <div className="fade-in"></div>
                        <div className="fade-out"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="box">
                        <div className="title">Account Information</div>
                        <div className="subtitle">View your account information</div>
                        <div className="form-group">
                            <div className="input-title">Account Type</div>
                            <Input type="text"
                                className="text-input"
                                placeholder={this.state.admin ? "Admin" 
                                             : this.state.local_admin ? "Local Admin"
                                             : "User"}
                                readOnly={true}
                            />
                        </div>
                        <div className="form-group">
                            <div className="input-title">Username</div>
                            <Input type="text"
                                className="text-input"
                                placeholder={this.state.username}
                                readOnly={true}
                            />
                        </div>
                        <div className="form-group">
                            <div className="input-title">First Name</div>
                            <Input type="text"
                                className="text-input"
                                placeholder={this.state.first_name}
                                readOnly={true}
                            />
                        </div>
                        <div className="form-group">
                            <div className="input-title">Last Name</div>
                            <Input type="text"
                                className="text-input"
                                placeholder={this.state.last_name}
                                readOnly={true}
                            />
                        </div>
                        <div className="form-group">
                            <div className="input-title">Company</div>
                            <Input type="text"
                                className="text-input"
                                placeholder={this.state.company}
                                readOnly={true}
                            />
                        </div>
                        <div className="form-group">
                            <div className="input-title">Site Access</div>
                            <div className="sites">
                                {this.state.user_site_ids !== [] ? this.state.site_components : <div className="subtitle">No Site Access</div>}
                            </div>
                        </div>
                        <div className="subtitle">Something doesn't look right? Contact an admin.</div>
                    </div>
            </div>
            {this.loaded()}
                <ToastContainer
                    position="bottom-center"
                    autoClose={3000}
                    hideProgressBar
                    closeOnClick
                    draggable
                    pauseOnFocusLoss={false}
                    pauseOnHover={false}
                />
            </div>
        )
    }
}