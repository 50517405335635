import React, { Component } from 'react';
import { Button } from 'reactstrap';
import Report from 'powerbi-report-component';
import axios from 'axios';
import $ from 'jquery';
import Header from '../../components/header/header';
import User from '../../classes/user';
import trackPage from '../../functions/googleAnalytics'

import './home.scss';

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      token: '',
      url: '',
      id: '',
      site_access: '',
    }
    trackPage(window.location.pathname + window.location.search)
  }



  async componentDidMount() {
    this.postData();
  }

  reportLoaded = async () => {
    $(".loading-screen").fadeOut(1000);
  }

  postData = async () => {
    let sites = await User.getSiteAccess();
    let report = await User.getReport("production");

    for(var i=0; i < sites.length; i++) {
      sites[i] = sites[i].replace(':', '');
    }

    await this.setState({ site_access: JSON.stringify(sites) });
    console.log(report);

    try {
      var response = await axios.get('https://gv4frplfsa.execute-api.us-east-1.amazonaws.com/dev/token/default', {
        params: {
          reportid: report.id,
          groupid: report.group_id,
          roles: this.state.site_access
        },
        headers: {
          "x-api-key": "MktlxGN1KyaVXRLKZtBAB5zeY3ogrzP117ynUyjo"
        }
      })
      console.log(response);
      this.setState({ loading: false, token: response.data.pbi_token, url: response.data.url, id: response.data.id});
    }
    catch(error) {
      alert("Unexpected " + error);
      console.log(error);
    }
  }

  fullscreen = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      var element = $('#container').get(0);
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  }

  render() {

    let contents = this.state.loading
      ? null
      : <Report
          embedType="report"
          tokenType="Embed"
          accessToken={this.state.token}
          embedUrl={this.state.url}
          embedId={this.state.id}
          permissions="View"
          extraSettings={{
            filterPaneEnabled: false
        }}
          onLoad={this.reportLoaded}
        />;

    return (
      <div className="home-page">
        <Header history={this.props.history} />
        <div className="buttonContainer">
          <i onClick={this.fullscreen} id="fullscreen" class="fas fa-expand"></i>
        </div>
        <div className="center">
          <div className="loading-screen">
            <div className="heart-rate">
              <svg version="1.0" x="0px" y="0px" width="150px" height="73px" viewBox="0 0 150 73" enable-background="new 0 0 150 73">
                <polyline fill="none" stroke="#38A2E8" stroke-width="3" stroke-miterlimit="10" points="0,45.486 38.514,45.486 44.595,33.324 50.676,45.486 57.771,45.486 62.838,55.622 71.959,9 80.067,63.729 84.122,45.486 97.297,45.486 103.379,40.419 110.473,45.486 150,45.486"/>
              </svg>
              <div className="fade-in"></div>
              <div className="fade-out"></div>
            </div>
          </div>
          <div id="container">
            {contents}
          </div>
        </div>
      </div>
    );
  }
}