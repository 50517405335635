import React, { Component } from 'react';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../components/header/header';
import $ from 'jquery';

import * as firebase from 'firebase';
import 'firebase/firestore';

import './new-user.scss';

export default class AdminNewUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      creatingUser: false,
      site_list_components: null,
      site_access_ids: [],
      local_admin: false
    }

    this.buildSiteList();
  }

  createUser = async () => {

    this.setState({ creatingUser: true });
    
    var inputsValid = true;
    $('.text-input').each(function () {
      if ($(this).val() === '') {
        inputsValid = false;
      }
    });

    if (!inputsValid) {
      toast.error('Missing inputs. All inputs are required.');
      this.setState({ creatingUser: false });
      return;
    }

    if (this.state.password !== this.state.confirm_password) {
      toast.error('Password do not match. Please try again.');
      this.setState({ creatingUser: false });
      return;
    }
    let id = sessionStorage.getItem('uid');
    firebase.auth().createUserWithEmailAndPassword(this.state.email, this.state.password).then(async (response) => {
      try {
        var db = firebase.firestore();
        var doc = await db.collection('users').doc(response.user.uid).get();
        var docData = {
          company: this.state.company,
          email: this.state.email,
          first_name: this.state.first_name,
          last_name: this.state.last_name,
          site_access_ids: this.state.site_access_ids, 
          local_admin: this.state.local_admin
        };
        doc.exists 
          ? db.collection('users').doc(response.user.uid).update(docData)
          : db.collection('users').doc(response.user.uid).set(docData);
      } catch (err) {
        console.log(err);
      }
      toast.success('User created!');
      this.resetUser(id);
      this.clearinputs();
      this.setState({ creatingUser: false });
    })
    .catch((err) => {
      toast.error(err.message);
      this.setState({ creatingUser: false });
      });
  }

  resetUser = (id) => {
    // Creating a user signs you in as them so we just set the uid back to the original users
    sessionStorage.setItem('uid', id);
  }

  clearinputs = () => {
    $('.text-input').each(function() {
      $(this).val('');
    });
    $('.site-iterate').each(function (index, v) {
      let checkbox = $('.checkbox')[index];
      $(checkbox).prop('checked', false);
    });
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.createUser();
    }
  }

  local_admin = async (e) => {
    if (e.target.checked) {
      await this.setState({ local_admin: true })
    }
    else {
      await this.setState({ local_admin: false })
    }
  }

  site_changed = async (e) => {
    if (e.target.checked) {
      this.state.site_access_ids.push(parseInt(e.target.value));
    } else {
      this.state.site_access_ids = this.state.site_access_ids.filter((item) => {
        return item !== parseInt(e.target.value);
      });
    }
  }

  buildSiteList = async () => {
    var db = firebase.firestore();
    var _this = this;
    let localAdmin = false;
    let local_sites = [];
    if (sessionStorage.getItem('local_admin')) {
      var data = await db.collection('users').doc(sessionStorage.getItem('uid')).get();
      localAdmin = true;
      local_sites = data.data().site_access_ids;
    }
    db.collection('data').doc('sites').get().then((doc) => {
      var site_list = doc.data();
      var site_list_components = [];

      var sorted_list = [];
      if (localAdmin) {
        for (let site in site_list) {
          if (local_sites.includes(parseInt(site))) {
            console.log(site);
            sorted_list.push([site, site_list[site]]);
          }
        }
      }
      else {
        for (let site in site_list) {
          sorted_list.push([site, site_list[site]]);
        }
      }
      sorted_list.sort((a, b) => {
        var x = a[1].toLowerCase();
        var y = b[1].toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
      });

      sorted_list.forEach((site) => {
        site_list_components.push(
          <FormGroup className="site-iterate" key={site[0]} check>
            <Label check>
              <Input type="checkbox" className="checkbox" value={site[0]} onClick={this.site_changed}/>
              {site[1]}
            </Label>
          </FormGroup>
        );
      });
      
      _this.setState({site_list_components});
    })
    .catch((err) => {
      console.log(err);
    });
  }

  render() {
    return (
      <div className="admin-new-user">
        <Header history={this.props.history} />
        <div className="container">
          <div className="box">
            <div className="title">Create New User</div>
            <div className="subtitle">Fill out the form to create a new user</div>
            <div className="form-group">
              <div className="input-title">First Name</div>
              <Input type="text" 
                className="text-input"
                placeholder="First Name" 
                onChange={(event) => {this.setState({first_name: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div className="form-group">
              <div className="input-title">Last Name</div>
              <Input type="text"
                className="text-input"
                placeholder="Last Name" 
                onChange={(event) => {this.setState({last_name: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div className="form-group">
              <div className="input-title">Company</div>
              <Input type="text" 
                className="text-input"
                placeholder="Company" 
                onChange={(event) => {this.setState({company: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div className="form-group">
              <div className="input-title">Email Address</div>
              <Input type="text" 
                className="text-input"
                placeholder="Email Address" 
                onChange={(event) => {this.setState({email: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div className="form-group">
              <div className="input-title">Password</div>
              <Input type="password" 
                className="text-input"
                placeholder="Password" 
                onChange={(event) => {this.setState({password: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div className="form-group">
              <div className="input-title">Confirm Password</div>
              <Input type="password" 
                className="text-input"
                placeholder="Confirm Password" 
                onChange={(event) => {this.setState({confirm_password: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
            </div>
            <div className="form-group">
              <div className="input-title">Site Access</div>
              <div className="sites">
                {this.state.site_list_components}
              </div>
            </div>

            <FormGroup className="" check>
              <Label check>
                <Input type="checkbox" className="checkbox" onClick={this.local_admin} />
                Local Admin
              </Label>
            </FormGroup>

            <Button className="create-user-button" 
              onClick={this.createUser}
              disabled={this.state.creatingUser}
            >
              {this.state.creatingUser ?
                <div>
                  <b>Creating User     </b>
                  <i class="fas fa-spinner fa-spin"></i>
                </div>  
                :
                <div>
                  <b>Create User</b>
                </div>
              }
            </Button>
          </div>
        </div>
        <ToastContainer 
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    )
  }
}