import React, { Component } from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import * as firebase from 'firebase';

// pages
import Login from './pages/login/login';
import ForgotPassword from './pages/forgot-password/forgot-password';
import ResetPassword from './pages/reset-password/reset-password';
import NewPassword from './pages/new-password/new-password';
import Home from './pages/home/home';
import AdminNewUser from './pages/admin/new-user/new-user';
import AdminEditUser from './pages/admin/edit-user/edit-user';
import AdminDevReport from './pages/admin/dev-report/dev-report';
import AdminUpdateReport from './pages/admin/update-report/update-report';
import NotFound from './pages/not-found/not-found';
import ViewAccount from './pages/view-account/view-account';

// Initialize Firebase
const firebaseConfig = {
  apiKey: "AIzaSyAoBRffbkfYtW7742OeALCKy4U8ypGytmA",
  authDomain: "raamspulse.firebaseapp.com",
  databaseURL: "https://raamspulse.firebaseio.com",
  projectId: "raamspulse",
  storageBucket: "raamspulse.appspot.com",
  messagingSenderId: "602232909911",
  appId: "1:602232909911:web:42dd70dd6c3d2ea9"
};
firebase.initializeApp(firebaseConfig);

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    sessionStorage.setItem('uid', user.uid);
  } else {
    sessionStorage.clear();
  }
});

const createHistory = require("history").createBrowserHistory;
const history = createHistory()

// session for debugging
//sessionStorage.clear();
//sessionStorage.setItem('admin', 'true');
//sessionStorage.setItem('uid', '1');

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    sessionStorage.getItem('uid')
      ? <Component {...props} />
      : <Redirect to='/login' />
  )} />
)

const AdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    sessionStorage.getItem('admin')
      ? <Component {...props} />
      : <Redirect to='/home' />
  )} />
)

const LocalAdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
    sessionStorage.getItem('local_admin') || sessionStorage.getItem('admin')
      ? <Component {...props} />
      : <Redirect to='/home' />
  )} />
)

if (window.location.pathname === '/') {
  if (sessionStorage.getItem('uid')) {
    window.location.replace('/home');
  } else {
    window.location.replace('/login');
  }
}

class App extends Component {

  render() {
    return (
      <Router history={history}>
        <div className="App">
            <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/forgot-password" component={ForgotPassword} />
                <Route exact path="/reset-password" component={ResetPassword} />
                <PrivateRoute exact path="/new-password" component={NewPassword} />
                <PrivateRoute exact path="/home" component={Home} />
                <PrivateRoute exact path="/view-account" component={ViewAccount} />
                <LocalAdminRoute exact path="/admin/new-user" component={AdminNewUser} />
                <AdminRoute exact path="/admin/new-user" component={AdminNewUser} />
                <AdminRoute exact path="/admin/edit-user" component={AdminEditUser} />
                <AdminRoute exact path="/admin/dev-report" component={AdminDevReport} />
                <AdminRoute exact path="/admin/edit-report" component={AdminUpdateReport} />
                <Route path='*' exact={true} component={NotFound} />
            </Switch>
        </div>
      </Router>
    );
  }
  
}

export default App;