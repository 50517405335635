import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../components/header/header';
import SweetAlert from 'sweetalert2-react';

import * as firebase from 'firebase';

import './forgot-password.scss';

export default class ForgotPassword extends Component {

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      showAlert: false
    }
  }

  resetPressed = async () => {
    if (this.state.email === '') {
      toast.error('Email cannot be blank!');
      return;
    }

    firebase.auth().sendPasswordResetEmail(this.state.email).then(() => {
      this.setState({showAlert: true});
    })
    .catch((err) => {
      if (err.message) {
        toast.error(err.message);
      } else {
        toast.error('An unknown error has occured.');
      }
    });
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.resetPressed();
    }
  }

  render() {
    return (
      <div className="forgot-page">
        <Header history={this.props.history} />
        <SweetAlert
          show={this.state.showAlert}
          title="Reset Initiated"
          text="An email has been sent to you with further instructions to reset your password."
          type="success"
          confirmButtonText="Go to login"
          confirmButtonColor="#38A2E8"
          onConfirm={() => {this.props.history.push('/login')}}
        />
        <div className="forgot-container">
          <div className="forgot-box">
            <div className="forgot-title">
              Reset Password
            </div>
            <div className="forgot-text">
              Please enter your email address to request a password reset.
            </div>
            <Input type="text" 
              placeholder="Email" 
              onChange={(event) => {this.setState({email: event.target.value})}} 
              onKeyDown={this.handleKeyPress}
            />
            <Button className="reset-button"
              onClick={this.resetPressed}
            >
              Reset Password
            </Button>
            <Button color="link"
              onClick={() => {this.props.history.push('/login')}}
            >
              Back to login
            </Button>
          </div>
        </div>
        <ToastContainer 
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    )
  }
}