import React, { Component } from 'react';
import { Button, Input } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import SweetAlert from 'sweetalert2-react';

import Header from '../../components/header/header';
import * as firebase from 'firebase';

import './reset-password.scss';

const queryString = require('query-string');

export default class ResetPassword extends Component {

  constructor(props) {
    super(props);

    var parsedUrl = queryString.parse(this.props.location.search);

    this.state = {
      verificationCode: parsedUrl.oobCode,
      password: '',
      confirmPassword: '',
      showAlert: false
    }
  }

  submitPressed = async () => {
    if (this.state.password === '' || this.state.confirmPassword === '') {
      toast.error('Missing password. Please enter and new password.');
      return;
    } else if (this.state.password !== this.state.confirmPassword) {
      toast.error('Passwords do not match. Please try again.');
      return;
    }

    firebase.auth().verifyPasswordResetCode(this.state.verificationCode).then((email) => {
      firebase.auth().confirmPasswordReset(this.state.verificationCode, this.state.password).then((response) => {
        this.setState({showAlert: true});
      })
      .catch((err) => {
        toast.error(err.message);
      });
    })
    .catch((err) => {
      toast.error('An error has occured. Please click the link in your email and try again.');
    });

  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.submitPressed();
    }
  }

  render() {
    if (this.state.verificationCode) {
      return (
        <div className="reset-password-page">
          <SweetAlert
            show={this.state.showAlert}
            title="Success"
            text="Your password has successfully been reset!"
            type="success"
            confirmButtonText="Go to login"
            confirmButtonColor="#38A2E8"
            onConfirm={() => {this.props.history.push('/login')}}
          />
          <Header history={this.props.history} />
          <div className="new-password-container">
            <div className="new-password-box">
              <div className="new-password-title">
                New Password
              </div>
              <div className="new-password-text">
                Please enter your new password below.
              </div>
              <Input type="password" 
                placeholder="New Password" 
                onChange={(event) => {this.setState({password: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
              <Input type="password" 
                placeholder="Confirm Password" 
                onChange={(event) => {this.setState({confirmPassword: event.target.value})}} 
                onKeyDown={this.handleKeyPress}
              />
              <Button className="save-button"
                onClick={this.submitPressed}
              >
                Submit
              </Button>
            </div>
          </div>
          <ToastContainer 
            position="bottom-center"
            autoClose={3000}
            hideProgressBar
            closeOnClick
            draggable
            pauseOnFocusLoss={false}
            pauseOnHover={false}
          />
        </div>
      )
    } else {
      return null;
    }
  }
}