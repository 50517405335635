import React, { Component } from 'react';
import { Button, Input, FormGroup, Label } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import Header from '../../../components/header/header';
import $ from 'jquery';

import * as firebase from 'firebase';
import 'firebase/firestore';

import './edit-user.scss';

export default class AdminNewUser extends Component {

  constructor(props) {
    super(props);
    this.state = {
      savingUser: false,
      site_list_components: null,
      original_site_list_components: null,
      user_component: null,
      local_admin: null,
      user_data: {
        first_name: '',
        last_name: '',
        company: '',
        email: '',
        site_access_ids: '',
      }
    }

    this.buildSiteList();
    this.buildUserList();
  }

  saveUser = async () => {

    this.setState({ savingUser: true });
    
    var inputsValid = true;
    $('input').each(function() {
      if ($(this).val() === '') {
        inputsValid = false;
      }
    });
    if (!inputsValid) {
      toast.error('Missing inputs. All inputs are required.');
      this.setState({ savingUser: false });
      return;
    }

    try {
      var db = firebase.firestore();
      var doc = await db.collection('users').doc(this.state.user_doc_id).get();
      var docData = {
        company: this.state.user_data.company,
        first_name: this.state.user_data.first_name,
        last_name: this.state.user_data.last_name,
        site_access_ids: this.state.user_data.site_access_ids,
        local_admin: this.state.local_admin
      };
      doc.exists 
        ? db.collection('users').doc(this.state.user_doc_id).update(docData)
        : db.collection('users').doc(this.state.user_doc_id).set(docData);
    } catch (err) {
      console.log(err);
    }
    toast.success('User saved!');
    this.setState({ savingUser: false });
  }

  clearinputs = () => {
    $('input').each(function() {
      $(this).val('');
    });
    $('.site-checkbox').each(function() {
      $(this).prop('checked', false);
    });
  }

  handleKeyPress = (e) => {
    if (e.keyCode === 13) {
      this.saveUser();
    }
  }

  site_changed = async (e) => {
    if (e.target.checked) {
      this.state.user_data.site_access_ids.push(parseInt(e.target.value))
    } else {
      this.state.user_data.site_access_ids = this.state.user_data.site_access_ids.filter((item) => {
        return item !== parseInt(e.target.value);
      });
    }
  }

  usernameChange = async (e) => {
    var email = e.target.value;
    let _this = this;
    if (email === '') {
      this.setState({  
        user_data: {
          first_name: '',
          last_name: '',
          company: '',
          email: ''
        } 
      });
    } else {
      await this.setState({ site_list_components: this.state.original_site_list_components })
      let db = firebase.firestore();
      let user_query = await db.collection('users').where('email', '==', email).get();
      let user_docs = user_query.docs;
      let user_doc = user_docs[0];

      let user_data = user_doc.data();
      this.setState({ user_data, user_doc_id: user_doc.id });
      let sites = []
      $('.site-iterate').each(function (index, v) {
        let checkbox = $('.site-checkbox')[index];
        let value = $(checkbox).val();
        if (user_data.site_access_ids.includes(parseInt(value))) {
          $(checkbox).prop('checked', true);
          sites.unshift(
            <FormGroup value={value} className="site-iterate" key={value} check>
            <Label check>
              <Input type="checkbox" value={value} className="site-checkbox" onClick={_this.site_changed} />
                {this.children[0].innerText}
            </Label>
            </FormGroup>
          );
        } else {
          $(checkbox).prop('checked', false);
          sites.push(
            <FormGroup value={value} className="site-iterate" key={value} check>
            <Label check>
                <Input type="checkbox" value={value} className="site-checkbox" onClick={_this.site_changed} />
                {this.children[0].innerText}
            </Label>
            </FormGroup>
          );
        }
      });
      this.setState({ site_list_components: sites })

      if (user_data.local_admin) {
        $(".checkbox-admin").attr("checked", true);
      }
      else {
        $(".checkbox-admin").attr("checked", false);
      }
    }
  }

  buildSiteList = async () => {
    var db = firebase.firestore();
    var site_doc = await db.collection('data').doc('sites').get()
    var site_list = site_doc.data();
    var site_list_components = [];

    var sorted_list = [];
    for (let site in site_list) {
      sorted_list.push([site, site_list[site]]);
    }
    sorted_list.sort((a, b) => {
      var x = a[1].toLowerCase();
      var y = b[1].toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });

    sorted_list.forEach((site) => {
      site_list_components.push(
        <FormGroup value={site[0]} className="site-iterate" key={site[0]} check>
          <Label check>
            <Input type="checkbox" value={site[0]} className="site-checkbox" onClick={this.site_changed}/>
            {site[1]}
          </Label>
        </FormGroup>
      );
    });

    this.setState({ site_list_components, original_site_list_components: site_list_components });
  }

  buildUserList = async () => {
    var db = firebase.firestore();
    var user_collection = await db.collection('users').get();
    var user_docs = user_collection.docs;
    var user_component = []
    user_component.push(
      <option key='none'></option>
    );

    var users = []
    for (let user_doc in user_docs) {
      var user_data = user_docs[user_doc].data();
      users.push(user_data.email);
    }

    users.sort();
    for (let user in users) {
      user_component.push(
        <option key={user}>{users[user]}</option>
      )
    }

    this.setState({user_component});
  }

  handleTextChange = (e) => {
    let target = e.target;
    let name = target.name;
    let value = target.value;
    this.setState(prevState => ({user_data: {...prevState.user_data, [name]: value}} ));
  }

  local_admin = async (e) => {
    if (e.target.checked) {
      await this.setState({ local_admin: true })
    }
    else {
      await this.setState({ local_admin: false })
    }
  }

  render() {   
    return (
      <div className="admin-edit-user">
        <Header history={this.props.history} />
        <div className="container">
          <div className="box">
            <div className="title">Edit User</div>
            <div className="subtitle">Select a user from the list to edit</div>
            <FormGroup>
              <Label>Select username</Label>
              <Input type="select" name="select" id="select_username" className="select-box" onChange={this.usernameChange}>
                {this.state.user_component}
              </Input>
            </FormGroup>
            <div className={this.state.user_data.email === '' ? 'hidden' : ''}>
              <div className="form-group">
                <div className="input-title">First Name</div>
                <Input type="text" 
                  className="text-input"
                  placeholder="First Name" 
                  onChange={this.handleTextChange} 
                  onKeyDown={this.handleKeyPress}
                  defaultValue={this.state.user_data.first_name}
                  name='first_name'
                />
              </div>
              <div className="form-group">
                <div className="input-title">Last Name</div>
                <Input type="text"
                  className="text-input"
                  placeholder="Last Name" 
                  onChange={this.handleTextChange} 
                  onKeyDown={this.handleKeyPress}
                  defaultValue={this.state.user_data.last_name}
                  name='last_name'
                />
              </div>
              <div className="form-group">
                <div className="input-title">Company</div>
                <Input type="text" 
                  className="text-input"
                  placeholder="Company" 
                  onChange={this.handleTextChange}              
                  onKeyDown={this.handleKeyPress}
                  defaultValue={this.state.user_data.company}
                  name='company'
                />
              </div>
              <div className="form-group">
                <div className="input-title">Site Access</div>
                <div className="sites">
                  {this.state.site_list_components}
                </div>
              </div>

              <FormGroup id="localadmin" check>
                <Label check>
                  <Input type="checkbox" className="checkbox-admin" onClick={this.local_admin} />
                  Local Admin
              </Label>
              </FormGroup>

              <Button className="create-user-button" 
                onClick={this.saveUser}
                disabled={this.state.savingUser}
              >
                {this.state.savingUser ?
                  <div>
                    <b>Saving User     </b>
                    <i class="fas fa-spinner fa-spin"></i>
                  </div>  
                  :
                  <div>
                    <b>Save User</b>
                  </div>
                }
              </Button>
            </div>
          </div>
        </div>
        <ToastContainer 
          position="bottom-center"
          autoClose={3000}
          hideProgressBar
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover={false}
        />
      </div>
    )
  }
}